import { AnimatePresence } from "framer-motion"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => (
  <>
    <Script
      id="_script1"
      dangerouslySetInnerHTML={{
        __html: `window._privacy = window._privacy || []`,
      }}
    />
    <Script
      id="_script2"
      dangerouslySetInnerHTML={{
        __html: `   window.cmpProperties = {
            consentType: 'BASIC',
            privacyManagerId: '417287',
            baseUrl: 'https://Streamz',
            cmpCname: 'https://cmp.streamz.be',
            featureFlags: {
            unified: true,
         },
         }`,
      }}
    />
    <Script
      id="_script3"
      src="https://myprivacy-static.dpgmedia.net/consent.js"
    />
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </>
)
